/*!
 * Bootstrap Grid v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import "bootstrap/bootstrap-reboot";
@import "bootstrap/bootstrap-grid";
@import "bootstrap/alert";
@import "bootstrap/close";
@import "bootstrap/tables";
@import "bootstrap/pagination";

/*@import "bootstrap/bootstrap";*/
@import "jquery-ui/core";
@import "jquery-ui-plugins/plugins";
@import "fontawesome/fontawesome";
@import "fontawesome/fa-brands";
@import "fontawesome/fa-regular";
@import "fontawesome/fa-solid";
@import "select2/core";
@import "icheck/icheck";
@import "scrollbar/scrollbar";
@import "common/common";

